<template>
<div>
    <div class="wrap4">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con">
                <div class="con_table4">
                    <div class="con_top mb-10">
                        <h2><span>■</span> 기본정보</h2>
                    </div>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <td rowspan="8" class="w-200px pd-00">

                                    <img v-if="photoUrl !== ''" :src="photoUrl" class="w-150px" />
                                    <span v-else class="w-200px"> 사진없음 </span>
                                </td>
                                <td class="left pl-20" colspan="4">
                                    {{name}} [{{id}} ({{gender}})]
                                </td>
                            </tr>
                            <tr>
                                <th>재직현황</th>
                                <td class="left pl-20" colspan="3">
                                    {{duty}}
                                </td>
                            </tr>
                            <tr>
                                <th>휴대폰</th>
                                <td class="left pl-20" colspan="3">
                                    {{phone}}
                                </td>
                            </tr>
                            <tr>
                                <th>이메일</th>
                                <td class="left pl-20" colspan="3">
                                    {{email}}
                                </td>
                            </tr>
                            <tr>
                                <th>생년월일</th>
                                <td class="left pl-20">
                                    {{birth}}
                                </td>
                                <th>최종학력</th>
                                <td class="left pl-20">
                                    {{education}} ({{educationInfo}})
                                </td>
                            </tr>
                            <tr>
                                <th>경력</th>
                                <td class="left pl-20">
                                    {{career}}년
                                </td>
                                <th>상담센터</th>
                                <td class="left pl-20">
                                    {{centerName}}
                                </td>
                            </tr>
                            <tr>
                                <th>전문분야</th>
                                <td class="left pl-20" colspan="3">
                                    {{proName}}
                                </td>
                                <!-- <th>회기당요율</th>
                                <td class="left pl-20">
                                    {{pay}} %
                                </td> -->
                            </tr>
                            <tr>
                                <th> 자격증</th>
                                <td class="left" colspan="3">
                                    <span v-for="(item, index) of certiList " :key="index" :class="{'ml-10': !item.delete}" style="cursor:pointer">
                                        <a :href="item.certificate_url" target="black" class="w-300px pointer txt-black">{{item.certificate_name}}</a>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mt-10 mb-00">
                        <tbody>
                            <tr>
                                <th class="w-120px">한줄소개</th>
                                <td class="left pl-20">
                                    {{introduce}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="default_table mt-20">
                        <tbody>
                            <tr>
                                <th class="reset"></th>
                                <th>09:00</th>
                                <th>09:30</th>
                                <th>10:00</th>
                                <th>10:30</th>
                                <th>11:00</th>
                                <th>11:30</th>
                                <th>12:00</th>
                                <th>12:30</th>
                                <th>13:00</th>
                                <th>13:30</th>
                                <th>14:00</th>
                                <th>14:30</th>
                                <th>15:00</th>
                                <th>15:30</th>
                                <th>16:00</th>
                                <th>16:30</th>
                                <th>17:00</th>
                                <th>17:30</th>
                                <th>18:00</th>
                                <th>18:30</th>
                                <th>19:00</th>
                                <th>19:30</th>
                                <th>20:00</th>
                                <th>20:30</th>
                                <th>21:00</th>
                                <th>21:30</th>
                            </tr>
                            <tr v-for="(item, idx) of workTimeList" :key="idx">
                                <td>
                                    {{item.day}}
                                </td>
                                <td v-for="(subItem, index) of item.table" :key="index" :class="{'back_off': subItem.use}"></td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- <div class="con_top mb-10">
                        <h2><span>■</span>상담일지 목록</h2>
                    </div>

                    <table class="default_table">
                        <tbody>
                            <tr>
                                <th>날짜</th>
                                <th>상담사</th>
                                <th>상담내용</th>
                                <th>관리</th>
                            </tr>
                            <tr v-for="(item, index) of reportList" :key="index">
                                <td>{{item.reportDate.slice(0,10)}}</td>
                                <td>{{item.staffName}}</td>
                                <td>{{item.consContents}}</td>
                                <td><span class="underline pointer" @click="detail()">상세보기</span></td>
                            </tr>
                        </tbody>
                    </table> -->

                    <div class="btns3 mgB80">
                        <a class="btn_sms mr-10 pointer" @click="prev()">목록</a>
                        <a class="btn_cancle mr-10 pointer" @click="modify()">수정</a>
                        <a class="btn_cancle pointer" @click="del()">삭제</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import {
    GetUrlParams
} from '@/utils/common.js'
export default {
    data: () => ({
        // 상담일지목록
        //reportList: [],
        id: '', // 아이디
        pwd: '', // 비밀번호,
        name: '', // 이름
        gender: '남자', // 성별
        phone: '',
        phone1: '010', // 휴대폰 앞자리
        phone2: '', // 휴대폰 가운데자리
        phone3: '', // 휴대폰 마지막자리
        email: '', // 이메일
        email1: '', // 이메일 앞자리
        email2: '', // 이메일 뒷자리
        email3: '', // 이메일 도메인 선택시
        birthYY: 2020, // 생년월일 YYYY
        birthMM: 1, // 생년월일 MM
        birthDD: 1, // 생년월일 DD
        birth: '', // YYYY-MM-DD
        education: '고등학교', // 최종학력
        educationInfo: '', // 학교/전공
        career: 1, // 경력
        introduce: '', // 한줄소개
        duty: '재직', // 재직현황
        idxCrmCenter: -1, // center idx
        idxCrmMetaPro: -1, // 전문분야
        proName: '',
        pay: 0, // 회기당 급여
        idx: -1, // 상담사 고유 idx

        profileFiles: [],

        workTimeList: [], // 근무요일 타임테이블

        certFiles: [], // 자격증 파일 리스트 (최대 5개)
        certFileNames: [], // 자격증 파일명 리스트
        certFileUrls: [],
        profileFile: '', // 프로필 사진 파일
        photoName: '', // 프로필 사진 파일명
        photoUrl: '', // 프로필 사진 url
        autoSearchCenter: '', // 센터 자동완성 검색
        isAutoCenter: false, // 센터 자동완성 on/off
        centerName: '', // 센터명
        certiList: [],
        proList: [],

    }),

    mounted() {
        this.setWorkTimeList()
        var oParams = GetUrlParams()
        this.idx = oParams.idx
        this.getUserInfo(this.idx)
        this.getMetaList()
    },

    methods: {

        // 유입경로, 상담구분, 상담유형 리스트
        getMetaList() {
            this.axios.get('/api/v1/client/meta', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.proList = res.data.proList
                    }
                })
        },

        // 상담사관리 페이지로 돌아가기
        prev() {
            //this.$router.push('/convention/counselor_manage')
            history.back()
        },

        // 상담사 데이터 api 호출
        getUserInfo(idx) {
            var params = {
                idx: idx
            }

            this.axios.get(`/api/v1/user/${idx}`, {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        for (var item of Object.keys(res.data.userVO)) {
                            if (typeof this[item] !== 'undefined') {
                                if (item === 'workTimeList') {
                                    continue
                                }

                                // 생년월일 데이터 매핑
                                if (item === 'birth') {
                                    console.log('fdssfd')
                                    var birth = res.data.userVO[item].split('-')
                                    this.birthYY = birth[0]
                                    this.birthMM = birth[1]
                                    this.birthDD = birth[2]
                                    this.birth = birth[0] + '.' + birth[1] + '.' + birth[2]
                                    continue
                                }

                                // 이메일 매핑
                                if (item === 'email') {
                                    if (res.data.userVO[item].includes('@')) {
                                        var email = res.data.userVO[item].split('@')
                                        this.email1 = email[0]
                                        this.email2 = email[1]
                                        this.email = res.data.userVO[item]
                                    }
                                    continue
                                }

                                // 사진 매핑
                                if (item === 'photoUrl') {
                                    if (res.data.userVO[item] !== null) {
                                        if (res.data.userVO[item].includes('https://kr.object.ncloudstorage.com')) {
                                            this[item] = res.data.userVO[item]
                                        } else {
                                            this[item] = ''
                                        }
                                    } else {
                                        this[item] = ''
                                    }
                                    continue
                                }

                                this[item] = res.data.userVO[item]
                                this.idxCrmMetaPro = res.data.userVO.idxCrmMetaPro

                                for (var pro of this.proList) {
                                    if (pro.idx === this.idxCrmMetaPro) {
                                        this.proName = pro.type
                                    }
                                }

                                this.duty = res.data.userVO.duty
                            }
                        }

                        // 자격증 매핑
                        /* for (var certi of res.data.certiList) {
                            this.certFileNames.push(certi.certificate_name)
                            this.certFileUrls.push(certi.certificate_url)
                        } */
                        /* 
                        for (var certi of res.data.reportList) {

                            if(certi.consContents !== undefined && certi.consContents !== '' && certi.consContents !== null) {

                                if(certi.consContents.length > 20) {
                                    certi.consContents = certi.consContents.substr(0, 20) + "..."
                                } 
                            }
                            this.reportList.push(certi)
                        } */

                        this.certiList = res.data.certiList

                        // 근무요일 테이블 매핑
                        for (var resWorkTime of res.data.workTimeList) {
                            for (var dataWorkTime of this.workTimeList) {
                                if (resWorkTime.day === dataWorkTime.day) {
                                    for (var partTime of dataWorkTime.table) {
                                        if (resWorkTime.time === partTime.time) {
                                            partTime.use = true
                                        }
                                    }
                                }
                            }
                        }

                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        // 근무요일 기본 테이블구조 변수에 할당
        setWorkTimeList() {
            this.workTimeList = []
            var days = ['월', '화', '수', '목', '금', '토', '일']
            for (var day of days) {
                var tempDayTable = {
                    day: day,
                    table: []
                }
                let time = 9
                for (var index = 0; index < 26; index++) {
                    //time += index
                    var stringTime = ''
                    /* if (time >= 10) {
                        stringTime = time.toString() + ':' + '00'
                    } else {
                        stringTime = '0' + time.toString() + ':' + '00'
                    } */
                    if (time >= 10) {
                        if (index % 2 == 0) {
                            stringTime = time.toString() + ':' + '00'
                        } else {
                            stringTime = time.toString() + ':' + '30'
                            time += 1
                        }
                    } else {
                        if (index % 2 == 0) {
                            stringTime = '0' + time.toString() + ':' + '00'
                        } else {
                            stringTime = '0' + time.toString() + ':' + '30'
                            time += 1
                        }
                    }
                    tempDayTable.table.push({
                        use: false,
                        day: day,
                        time: stringTime
                    })
                }
                this.workTimeList.push(tempDayTable)
            }
        },

        // 상담수정 페이지로 이동
        modify() {
            this.$router.push(`/convention/counselor_manage_modify?idx=${this.idx}`)
        },

        del() {
            var con_test = confirm("삭제하면 복구가 불가능합니다.\n정말 삭제하시겠습니까?");
            if (con_test == true) {
                this.axios.delete(`/api/v1/user/${this.idx}`, {})
                    .then(res => {
                        if (res.data.err === 0) {
                            alert(res.data.result)
                            //this.$router.go(this.$router.currentRoute)
                            history.back()
                        } else {
                            alert('삭제할 수 없습니다.')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                return false
            }
        }
    }
}
</script>
